<template>
	<div>
		<router-link class="nav-link col-6" active-class="active" :to="{ name: 'tiersCoordonnees'}" href="#">
            <h2>{{ $t('tiers.menu_coordonnees') }}</h2>
        </router-link>

		<div class="box">
			<p>
				<label>{{ $t('tiers.adresse') }}</label> : 
				{{
					[
						tiers.tiers_address1,
						tiers.tiers_address2,
						`${tiers.tiers_postalcode} ${(tiers.tiers_town || '').toUpperCase()}`.trim(),
						country,
						tiers.tiers_region ? '(' + tiers.tiers_region + ')' : null
					].filter(Boolean).join(', ')
				}}
			</p>
			
			<Phone ref="phoneComponent" :tiers_id="tiers.tiers_id"></Phone>

			<p v-if="tiers.tiers_mail">
				<e-copybox mail :label="$t('tiers.email')" :value="tiers.tiers_mail"></e-copybox>
			</p>
			<p v-if="tiers.tiers_website">
				<label>{{ $t('tiers.site') }}</label> : {{ tiers.tiers_website }}
			</p>
			<p v-if="tiers.tiers_statut_rcs">
				<label>{{ $t('tiers.has_pappers_verif') }}</label> {{ tiers.tiers_statut_rcs }}
			</p>
			<p v-if="tiers.tiers_statut_rne">
				<label>{{ $t('tiers.has_pappers_verif_rne') }}</label> {{ tiers.tiers_statut_rne }}
			</p>

			<p v-if="tiers.tiers_vatcheck > 0" :class="tvaMessageColor">
				{{ tvaMessage }}
			</p>
			<div v-if="tiers.tiers_statut_rcs === 'Radié'">
				<b-alert show variant="warning" class="mt-2">
					<font-awesome-icon :icon="['fal', 'exclamation-triangle']" style="color: #8F5922;"/>
					{{ $t('tiers.rcs_radie') }}
				</b-alert>
			</div>
			<div v-else-if="tiers.tiers_statut_rne === 'Radié'">
				<b-alert show variant="warning" class="mt-2">
					<font-awesome-icon :icon="['fal', 'exclamation-triangle']" style="color: #8F5922;"/>
					{{ $t('tiers.rne_radie') }}
				</b-alert>
			</div>

			<div class="text-center">
				<button class="btn btn-primary btn-rounded" @click.prevent="edit_tiers">{{ $t('global.modifier') }}</button>
				<button v-if="edit" class="btn btn-secondary btn-rounded" @click.prevent="openDuplicate">{{ $t('global.dupliquer_tiers') }}</button>
				<TiersModal :tiers="tiers" :callback="loadCoordonees" from="fiche"></TiersModal>
			</div>

			<ModalConfirm
					ref="confirm_duplique"
					id="confirm_duplique_c"
					:icon="['far', 'save']"
					:icon_accept="['fal', 'level-down']"
					:text_title="$t('modal.tiers_duplication.title')"
					:text_question="text_question"
					:text_button_ok="$t('action.dupliquer')"
					:text_button_accept="$t('modal.general.ok')"
					:text_button_cancel="$t('modal.general.annuler')"
					:callback_validation="duplicate"
					:callback_cancel="closeModal"
					:processing="processing"
				/>
		</div>
	</div>
</template>

<script type="text/javascript">
import { EventBus } from 'EventBus'
import Vue from 'vue'
import Tools from '@/mixins/Tools.js'
import TableAction from '@/mixins/TableAction.js'
import Tiers from "@/mixins/Tiers.js"


import Countries from '@/assets/lang/countries/countries'

import Phone from '@/components/Tiers/Phone';
import TiersModal from '@/components/Tiers/Form'

export default {
    name: 'coordonneesTiers',
    mixins: [Tools, TableAction, Tiers],
    props: {
        tiers: { type: Object, default: null},
        edit: { type: Boolean, default: true }
    },
	data() {
		return {
			processing: false,
			text_question: this.getTrad("modal.tiers_duplication.suppression"),
		}
	},

    methods: {
        edit_tiers()
        {

        	this.goToEditTiers([this.tiers])

        },
        loadCoordonees()
        {
        	this.$refs.phoneComponent.initComponent()
        },
		closeModal(){
			this.$refs.confirm_duplique.closeModal()
		},
		openDuplicate(){
			this.$refs.confirm_duplique.openModal()
		},
		duplicate() {
			if(!this.processing)
			{
				this.processing = true 
				this.duplicateTiers(this.tiers.tiers_id)
				.then(async (result) => {
					await this.$sync.force(true, true)
					this.successToast()
					this.$router.push({
						name: 'tiersTableauBord',
						params: {
							tiers_id: result.tiers_id
						}
					})
				})
			}
		}
    },

    computed: {
        country(){
        	return Countries[Vue.i18n.locale()].label[this.tiers.tiers_country]
        },
		tvaMessage(){
			return this.tiers.tiers_vatcheck == 1 ? this.$t('tiers.has_valid_vat') : this.$t('tiers.has_invalid_vat')
		},
		tvaMessageColor(){
			return this.tiers.tiers_vatcheck == 1 ? 'text-success' : 'text-danger'
		},
    },

    components: {
    	Phone,
		TiersModal,
		ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),

    }
}
</script>
